<template>
  <div v-if="!loading">
    <v-card
      class="mx-auto"
      color="transparent"
      elevation="0"
      :max-width="maxWidth"
    >
      <template v-if="document">
        <h2 class="defaultText--text">
          {{ $t(document.title) }}
        </h2>
        <p
          v-if="document.key === 'risks'"
          class="mt-3"
          v-html="$t(document.description, {
            assetIssuer: asset.subscriptionInformation.assetIssuer,
          })"
        ></p>
        <p v-else class="mt-3" v-html="$t(document.description)"></p>
        <v-card color="backgroundLight mt-5 px-5 py-4 rounded-lg d-flex rounded-lg">
          <v-simple-checkbox
            v-model="checkbox"
            color="primary"
            :id="document.key"
            class="align-self-start"
          ></v-simple-checkbox>
          <div
            class="checkbox-label mobile-text"
            @click="checkbox = !checkbox"
            v-html="$t(document.checkboxText)"
          />
        </v-card>
        <div v-if="!hideDocuments">
          <DownloadDocument
            v-for="(doc, index) in document.documents"
            :key="index"
            :document="doc"
            :maxWidth="maxWidth"
            :templateId="documentKey"
            :isSubscription="isSubscription"
            :asset="asset"
          />
        </div>
      </template>
      <v-alert v-else type="error">
        {{ `${$t('onboarding.complete.key')} ${document.key} ${$t('onboarding.complete.missing')}` }}
      </v-alert>
      <v-divider v-if="divider" class="mt-10"></v-divider>
    </v-card>
  </div>
  <div v-else class="d-flex justify-center mt-10">
    <Spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'SignDocumentTemplate',
  components: {
    DownloadDocument: lazyLoad('components/Documents/DownloadDocument'),
    Spinner,
  },
  props: {
    documentKey: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [String, Number],
      default: 760,
    },
    hideDocuments: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    isSubscription: {
      type: Boolean,
      default: false,
    },
    asset: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      checkbox: false,
      document: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'assetId',
    ]),
  },
  watch: {
    checkbox(newValue) {
      this.$emit('accepted', newValue);
    },
  },
  async created() {
    const input = {
      documentTemplateId: this.documentKey,
    };
    if (this.isSubscription) {
      input.assetId = this.assetId;
    }
    this.document = await this.$store.dispatch('documents/getDocumentTemplate', input);
    this.loading = false;
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
}
.checkbox-label {
  width: 97%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .mobile-text {
    font-size: 12px !important;
  }
}
</style>
